<template>
  <div class="pa-3">
    <Message
        :message="message"
        @closeAlert="message.show = false"
    ></Message>

        <HeadlineTableView
            :icon="notification.icon"
            :headline="notification.title"
            :button-icon="''"
            :button-text="''"
        ></HeadlineTableView>

    <v-card flat class="rounded-xl pa-2 pa-sm-8" >
     {{notification.text}}
    </v-card>


  </div>
</template>

<script>

import {mapGetters} from "vuex";
import {error, msgObj} from "@/helper/helper";
const Message = () => import("@/components/generalUI/Message");
const HeadlineTableView = () => import("@/components/tableView/HeadlineTableView");

export default {
  name: "Notification",
  components: {
    Message,
    HeadlineTableView
  },
  computed: {
    ...mapGetters('auth', {
      user: 'user',
    }),
    ...mapGetters('notification', {
      notification: 'notification',
    }),
  },
  mounted() {
    window.scrollTo(0, 0)
    this.$store.dispatch('notification/getNotification', {
      uid: this.user.id,
      notificationId: this.$route.params.id
    }).catch((err) => {
      this.message = error(err)
    }).then(()=>{
          this.$store.dispatch('customer/editField', {
            entity: "notifications",
            uid: this.user.id,
            id: this.$route.params.id,
            newValue: true,
            row: 'read'
          }).then(() => {
            this.$store.dispatch('notification/getNotifications', {
              uid: this.user.id,
              onlyUnread: true
            }).catch((err) => {
              this.message = error(err)
            })
          }).catch((err)=>{
            this.message = error(err)
          })
    })
  },
  data() {
    return {
      message: msgObj(),
    }
  },
  methods: {}
}
</script>

<style lang="sass" scoped>
</style>
<style scoped>
</style>
